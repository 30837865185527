<template>
  <section id="donations">
    <div :class="$vuetify.breakpoint.smAndDown ? 'donations-responsive' : 'donations'">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-row>
            <v-col cols="12" sm="6" lg="'6" xl="6" class="text-center" style="height: 600px;">
              <div style="height: 100%;" class="d-flex justify-center align-end">
                <div class="d-flex flex-column">
                <h1 v-if="!$vuetify.breakpoint.smAndDown" style="color: #e8bb45; font-size: 60px;">
                 Apoya a tus <br> reinas de crypto
                </h1>
                <h1 v-if="$vuetify.breakpoint.smAndDown" style="color: #e8bb45; font-size: 20px;">
                 Apoya a tus <br> reinas de crypto
                </h1>
                <p v-if="!$vuetify.breakpoint.smAndDown" class="mt-4 font-weight-medium white--text" style="padding: 0px 80px; font-size: 20px; margin-bottom: 50px;">
                  Dona en esta sección y apoya el crecimiento <br> de este canal para que más personas hagan <br> parte del mundo crypto
                </p>
                <p v-if="$vuetify.breakpoint.smAndDown" class="mt-4 font-weight-medium white--text" style="padding: 0px 80px; font-size: 15px; margin-bottom: 50px;">
                  Dona en esta sección y apoya el crecimiento <br> de este canal para que más personas hagan <br> parte del mundo crypto
                </p>
                <div class="d-flex justify-center align-center" style=" margin-bottom: 50px;">
                  <v-btn
                  v-if="!$vuetify.breakpoint.smAndDown"
                    v-bind="size"
                    rounded
                    outlined
                    :style="{ backgroundImage: createBackgroundString }"
                    class="mt-3"
                    href="/donations"
                    style="width: 250px; padding: 20px 40px;"
                  >
                   <p style="margin-top: 15px; font-size: 20px;">Donar con Cripto</p>
                  </v-btn>
                  <div v-if="$vuetify.breakpoint.smAndDown" style="display: flex; flex-direction: column;">
                    <v-btn
                    v-bind="size"
                    rounded
                    outlined
                    :style="{ backgroundImage: createBackgroundString }"
                    class="mt-3"
                    href="/donations"
                    style="width: 250px; padding: 20px 40px;"
                  >
                   <p style="margin-top: 15px; font-size: 20px;">Donar con Cripto</p>
                  </v-btn>
                  <v-btn
                  v-if="$vuetify.breakpoint.smAndDown"
                    v-bind="size"
                    rounded
                    outlined
                    :style="{ backgroundImage: createBackgroundString }"
                    class="mt-3"
                    href="https://wa.link/6fed0c"
                    style="width: 250px; padding: 20px 40px;"
                  >
                   <p style="margin-top: 15px; font-size: 20px;">Donar dinero</p>
                  </v-btn>
                  </div>
                </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6" lg="'6" xl="6" class="text-center" style="height: 600px;">
              <div style="height: 100%;" class="d-flex justify-center align-end">
                <div class="d-flex justify-center align-center" style=" margin-bottom: 20px; margin-right: 40px;">
                  <v-btn
                  v-if="!$vuetify.breakpoint.smAndDown"
                    v-bind="size"
                    rounded
                    outlined
                    :style="{ backgroundImage: createBackgroundString }"
                    class="mt-3"
                    href="https://wa.link/6fed0c"
                    style="width: 250px; padding: 20px 40px;"
                  >
                   <p style="margin-top: 15px; font-size: 20px;">Donar dinero</p>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    angle: '50',
      color1: '#d8ab3d',
      color2: '#e8bb45'
  }),
  computed: {
    size() {
      const size = { md: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    createBackgroundString() {
      return `linear-gradient(${this.angle}deg, ${this.color1}, ${this.color2})`;
    }
  }
};
</script>

<style scoped>
.donations {
  /* margin-top: -5px; */
  background-image: url("~@/assets/img/banner-donaciones.jpg");
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  width: 100%;
  /* background-position: center; */
}
.donations-responsive {
  /* margin-top: -5px; */
  background-image: url("~@/assets/img/banner-donaciones-mobile.jpg");
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  width: 100%;
  /* background-position: center; */
}
.title {
  color: #e8bb45; font-size: 60px;
}
.title-respo {
  color: #e8bb45; font-size: 30px;
}
</style>
