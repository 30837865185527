<template>
  <section id="footer" class="pb-8" :class="$vuetify.breakpoint.lgAndDown && !$vuetify.breakpoint.lgOnly ? 'footer-container-respon' : 'footer-container'">
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="9">
          <v-row v-if="$vuetify.breakpoint.lgOnly" justify="center">
            <v-col cols="12" sm="3" md="3" lg="3"  xl="3">
              <div class="d-flex justify-center align-center" style="height: 100px;">
                <v-img src="@/assets/img/NewLogo.png" max-width="100px" height="100px" />
              </div>
            </v-col>
            <v-col cols="12" sm="3" md="3" lg="3"  xl="3">
              <div class="d-flex justify-start align-center" style="height: 100px;  margin-top: 10px;">
                <p style="color: white;"><span style="font-weight: bold;">Nosotros:</span> <br> Sobre nosotros <br> Nuestros cursos</p>
              </div>
            </v-col>
            <v-col cols="12" sm="3" md="3" lg="3"  xl="3">
              <div class="d-flex justify-center align-center" style="height: 100px;">
                  <v-btn
                  target="_blank"
                  href="https://Facebook.com/alcambioapp"
                  fab
                  small
                  style="margin-right: 15px;"
                  color="#d8ab3d"
                >
                  <v-icon class="icon-rrss" color="black">mdi-youtube</v-icon>
                </v-btn>
                <v-btn
                target="_blank"
                href="https://t.me/joinchat/Z9x8nrxEhb0yNjBh"
                fab
                small
                style="margin-right: 15px;"
                color="#d8ab3d"
              >
              <v-icon color="black">fab fa-tiktok</v-icon>
              </v-btn>
                <v-btn
                target="_blank"
                href="https://t.me/joinchat/Z9x8nrxEhb0yNjBh"
                fab
                small
                style="margin-right: 15px;"
                color="#d8ab3d"
              >
              <v-icon color="black">fab fa-telegram-plane</v-icon>
              </v-btn>
                <v-btn
                  target="_blank"
                  href="https://twitter.com/reinasdecrypto1?s=08"
                  fab
                  small
                  style="margin-right: 15px;"
                  color="#d8ab3d"
                >
                  <v-icon class="icon-rrss" color="black">mdi-twitter</v-icon>
                </v-btn>
                <v-btn
                    target="_blank"
                    href="https://wa.link/6fed0c"
                    fab
                    small
                    style="margin-right: 15px;"
                    color="#d8ab3d"
                  >
                  <v-icon class="icon-rrss" color="black">mdi-whatsapp</v-icon>
                </v-btn>
                <v-btn
                    target="_blank"
                    href="https://www.facebook.com/reinas.de.crypto"
                    fab
                    small
                    style="margin-right: 15px;"
                    color="#d8ab3d"
                  >
                  <v-icon class="icon-rrss" color="black">mdi-facebook</v-icon>
                </v-btn>
                <v-btn
                  target="_blank"
                  href="https://www.instagram.com/reinasdecrypto/"
                  fab
                  small
                  style="margin-right: 15px;"
                  color="#d8ab3d"
                >
                  <v-icon class="icon-rrss">mdi-instagram</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" sm="3" md="3" lg="3"  xl="3">
              <div class="d-flex justify-end align-center" style="height: 100px;">
                <p style="color: white;"><span style="font-weight: bold;">Contáctanos:</span> <br> + 57 302 8548959<br> info@reinasdecrypto.com</p>
              </div>
            </v-col>
          </v-row>
          <div v-if="$vuetify.breakpoint.lgAndDown && !$vuetify.breakpoint.lgOnly" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
              <div class="d-flex justify-center align-center" style="height: 100px;">
                <v-img src="@/assets/img/NewLogo.png" max-width="100px" height="100px" />
              </div>
              <div class="d-flex justify-start align-center" style="height: 100px;  margin-top: 10px;">
                <p style="color: white; text-align: center;"><span style="font-weight: bold;">Nosotros:</span> <br> Sobre nosotros <br> Nuestros cursos</p>
              </div>
              <div class="d-flex justify-center align-center" style="height: 100px;">
                  <v-btn
                  target="_blank"
                  href="https://Facebook.com/alcambioapp"
                  fab
                  small
                  style="margin-right: 15px;"
                  color="#d8ab3d"
                >
                  <v-icon class="icon-rrss" color="black">mdi-youtube</v-icon>
                </v-btn>
                <v-btn
                target="_blank"
                href="https://t.me/joinchat/Z9x8nrxEhb0yNjBh"
                fab
                small
                style="margin-right: 15px;"
                color="#d8ab3d"
              >
              <v-icon color="black">fab fa-tiktok</v-icon>
              </v-btn>
                <v-btn
                target="_blank"
                href="https://t.me/joinchat/Z9x8nrxEhb0yNjBh"
                fab
                small
                style="margin-right: 15px;"
                color="#d8ab3d"
              >
              <v-icon color="black">fab fa-telegram-plane</v-icon>
              </v-btn>
                <v-btn
                  target="_blank"
                  href="https://twitter.com/reinasdecrypto1?s=08"
                  fab
                  small
                  style="margin-right: 15px;"
                  color="#d8ab3d"
                >
                  <v-icon class="icon-rrss" color="black">mdi-twitter</v-icon>
                </v-btn>
                <v-btn
                    target="_blank"
                    href="https://wa.link/6fed0c"
                    fab
                    small
                    style="margin-right: 15px;"
                    color="#d8ab3d"
                  >
                  <v-icon class="icon-rrss" color="black">mdi-whatsapp</v-icon>
                </v-btn>
                <v-btn
                    target="_blank"
                    href="https://www.facebook.com/reinas.de.crypto"
                    fab
                    small
                    style="margin-right: 15px;"
                    color="#d8ab3d"
                  >
                  <v-icon class="icon-rrss" color="black">mdi-facebook</v-icon>
                </v-btn>
                <v-btn
                  target="_blank"
                  href="https://www.instagram.com/reinasdecrypto/"
                  fab
                  small
                  style="margin-right: 15px;"
                  color="#d8ab3d"
                >
                  <v-icon class="icon-rrss">mdi-instagram</v-icon>
                </v-btn>
              </div>
              <div class="d-flex justify-end align-center" style="height: 100px;">
                <p style="color: white; text-align: center;"><span style="font-weight: bold;">Contáctanos:</span> <br> + 57 302 8548959<br> info@reinasdecrypto.com</p>
              </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  data: () => ({}),
};
</script>

<style scoped>
.footer-container{
  background-color: black; 
  height: 150px;
}

.footer-container-respon{
  background-color: black; 
  height: 450px;
}
</style>
