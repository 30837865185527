<template id="cart">
  <div class="body">
    <banner-component />
    <cart-component-vue />
    <proximamente-component />
    <new-products />
    <v-row style="background-color: white;">
      <div style="width: 100%; display: flex; flex-direction: row; justify-content: center;">
        <v-col class="col-12 col-md-12" style="padding: 10px 30px 0px 30px">
          <div style="width: 100%;">
            <h1 class="text-center font-weight-bold display-1" style="color: #ffc83e; padding: 20px;">
            Subscribete a nuestro newsletter
            </h1>
          </div>
        </v-col>
    </div>
    </v-row>
    <v-row style="padding: 0px; margin-top: -30px; background-color: white;">
      <div style="width: 100%; display: flex; flex-direction: row; justify-content: center;">
        <v-col class="col-12 col-md-6 p-0">
            <p class="text-center" style="color: black; padding: 20px;">
            Recibe todas las novedades del mercado y promociones de Reinas de Crypto
            </p>
        </v-col>
      </div>
    </v-row>
    <v-row style="margin-top: -25px; background-color: white; padding: 0px 0px 40px 0px;">
      <v-col class="col-12 col-md-12 p-0 d-flex justify-center align-center">
        <div v-if="!$vuetify.breakpoint.smAndDown" style="width: 50%;" class="d-flex justify-center align-center">
                <v-text-field  v-model="email" placeholder="ejemplo@gmail.com" outlined  style="width: 100%; background-color: white; height: 55px; border-top-left-radius: 20px; border-bottom-left-radius: 20px; border-top-right-radius: 0px; border-bottom-right-radius: 0px; border: 0px solid transparent"></v-text-field>
                <v-btn @click="submit" class="d-flex justify-center align-center" color="#ffc83e" style="height: 55px; border-top-left-radius: 0px; border-bottom-left-radius: 0px; border-bottom-right-radius: 20px; border-top-right-radius: 20px; border-left-color: white;"> 
                  <p style="color: black; margin-top: 15px;">Enviar -></p>  
                </v-btn>
              </div>
              <div v-if="$vuetify.breakpoint.smAndDown" style="width: 300px;" class="d-flex justify-center align-center">
                <v-text-field  v-model="email" placeholder="ejemplo@gmail.com" outlined  style="width: 50%; background-color: white; height: 55px; border-top-left-radius: 20px; border-bottom-left-radius: 20px; border-top-right-radius: 0px; border-bottom-right-radius: 0px; border: 0px solid transparent"></v-text-field>
                <v-btn @click="submit" class="d-flex justify-center align-center" color="#ffc83e" style="height: 55px; border-top-left-radius: 0px; border-bottom-left-radius: 0px; border-bottom-right-radius: 20px; border-top-right-radius: 20px; border-left-color: white;"> 
                  <p style="color: black; margin-top: 15px;">Enviar -></p>  
                </v-btn>
              </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CartComponentVue from "../components/Cart.component.vue";
import BannerComponent from "../components/banner/CartBanner.component.vue";
import ProximamenteComponent from "../components/proximamente/Proximamente.component.vue";
import NewProducts from '../components/NewProducts.vue'
import emailjs from "emailjs-com";
export default {
  name: "Cart",
  components: {
    BannerComponent,
    CartComponentVue,
    ProximamenteComponent,
    NewProducts,
},
  data: () => ({
    confirmDialog: false,
    name: "Usuario interesado en info",
    email: "",
    textArea: "Correo informativo",
  }),
  methods: {
    submit() {
      const content = {
        name: this.name,
        email: this.email,
        message: this.textArea
      };
      emailjs
        .send(
          "service_m9y5xup",
          "template_ty020c3",
          content,
          "user_gvJbilDm9ErIC4mqWGvPN"
        )
        .then(
          result => {
            console.log("SUCCESS!", result.text);
          },
          error => {
            console.log("FAILED...", error.text);
          }
        );
      // Reset form field
      this.name = "";
      this.email = "";
      this.textArea = "";
      this.$vuetify.goTo(0);
    },
  }
};
</script>

<style>
.body {
  background-color: #ededed !important;
}
</style>
