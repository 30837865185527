<template>
  <v-app>
    <navigation />
    <router-view></router-view>
    <customFooter />
  </v-app>
</template>

<script lang="ts">
// import {Search} from './services/youtube-seervice'
import navigation from "./components/Navigation.vue";
import customFooter from "./components/Footer.component.vue"
// const service = require('./services/youtube-seervice')
export default {
  name: "App",
  components: {
    navigation,
    customFooter
  },
  data: () => ({
    feed: 'https://www.youtube.com/feeds/videos.xml?channel_id=UC8KROrnEHSnnV3z5J_FoSIg',
    options: {
      apiKey: "AIzaSyBtXniZV2k8DSKSi_gHkldpXbuvq0-KrAY",
      term: 'laravel reporitory'
    }
  }),
  // async mounted() {
  //  service.search(this.options)
  // }
};
</script>