<template>
  <section id="references">
    <!-- <img src="~@/assets/img/Parallax.png" alt="" /> -->
    <div class="bg-opacity">
      <v-container fluid>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-row align="center" justify="center">
              <v-col sm="4" md="4" lg="4" xl="4" class="hidden-xs-only mt-16">
                <!-- <v-img
                  src="@/assets/img/work.png"
                  class="d-block ml-auto mr-auto"
                  max-width="350px"
                /> -->
              </v-col>

              <v-col
                cols="12"
                sm="8"
                md="8"
                lg="8"
                xl="8"
                class="colorTxt"
                style="z-index: 1"
              >
                <div class="text-center">
                  <h1
                    :class="
                      $vuetify.breakpoint.smAndDown
                        ? 'font-weight-bold display-1 margin-down'
                        : 'font-weight-bold display-1 margin-up'
                    "
                    style="color: #ffc83e;"
                  >
                    ¿ Quieres pertenecer al equipo <br>
                    y ser una reina de crypto ?
                  </h1>
                </div>
                <div class="text-center">
                  <p>
                   Dejanos tus datos y nos comunicaremos contigo
                  </p>
                </div>
                <v-dialog
                  v-model="dialog"
                  width="800"
                  no-click-animation
                  scrollable
                  content-class="modal-content"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="my-5 text-center">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          rounded
                          :style="{ backgroundImage: createBackgroundString }"
                          style="width: 200px;"
                        >

                        <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; padding: 15px 0px 0px 0px;">
                        <p style="font-size: 20px;">Ver más</p>
                      </div>
                        </v-btn>
                    </div>
                  </template>

                  <v-card class="card-body" style="height: 600px;">
                    <v-row >
                      <v-col cols="12">
                        <div style="height: 100%;">
                          <v-row style="height: 100%;">
                            <v-col cols="5" sm="5" md="5" lg="5"  xl="5" style="padding: 0px;">
                              <div :class="selectedTableData == 1 ? 'holding' : 'trading'">
                                
                              </div>
                            </v-col>
                            <v-col cols="7" sm="7" md="7" lg="7"  xl="7" style="padding: 0px;">
                              <div style=" width: 100%; height: 80%;">
                                <v-row>
                                  <v-col cols="12" md="12"  lg="12" xl="12">
                                    <div class="d-flex justify-end align-end" style="padding: 30px 10px 0px 0px;">
                                      <v-btn
                                      @click="dialog = false"
                                        fab
                                        small
                                        style="margin-right: 15px;"
                                        color="#ffffff"
                                      >
                                        <v-icon class="icon-rrss" color="black">mdi-close</v-icon>
                                      </v-btn>
                                    </div>
                                  </v-col>
                                </v-row>
                                <v-row align="center" justify="center" class="row-color">
                                  <v-col
                                    cols="4"
                                    md="4"
                                    lg="4"
                                    xl="4"
                                    class="text-center margin-col"
                                    :class="
                                        selectedTableData == 1
                                          ? 'tittle-active'
                                          : 'tittle-inactive'
                                      "
                                    >
                                    <span
                                      class="modal-title"
                                      
                                      @click="selectedTableData = 1"
                                      >Comienza con Trading</span
                                    >
                                  </v-col>
                                  <v-col
                                    cols="4"
                                    md="4"
                                    lg="4"
                                    xl="4"
                                    class="text-center margin-col"
                                    :class="
                                        selectedTableData == 2
                                          ? 'tittle-active'
                                          : 'tittle-inactive'
                                      "
                                  >
                                    <span
                                      class="modal-title"
                                      
                                      @click="selectedTableData = 2"
                                      >Trabaja con Holding</span
                                    >
                                  </v-col>
                                  <!-- <v-col
                                    cols="4"
                                    md="4"
                                    lg="4"
                                    xl="4"
                                    class="text-center  margin-col"
                                  >
                                    <span
                                      class="modal-title my-1"
                                      :class="
                                        selectedTableData == 3
                                          ? 'tittle-active'
                                          : 'tittle-inactive'
                                      "
                                      @click="selectedTableData = 3"
                                    >
                                      ¿ Eres modelo ?</span
                                    >
                                  </v-col> -->
                                </v-row>
                                <v-row
                                  v-if="selectedTableData == 1"
                                  justify="center"
                                  align="center"
                                  class="pb-5"
                                >
                                  <v-col cols="12">
                                    <v-row justify="center" align="center">
                                      <v-col cols="12" class="px-5">
                                        <div class="text-container">
                                          <div class="text-center">
                                            <p class="model-title">
                                              ¿ Estas interesado en comenzar en el mundo del
                                              trading ?
                                            </p>
                                          </div>
                                          <br />
                                         <p style="padding: 0px 20px; margin-top: -30px;">
                                          Envianos tus datos a través del siguiente
                                          formulario para que de esta manera podamos
                                          contactarnos contigo y de esta manera poder
                                          ayudarte a adentrarte en el mundo del trading,
                                          tambien podras recibir informacion de nuestros
                                          cursos en la plataforma de Patreon.
                                         </p>
                                        </div>
                                      </v-col>
                                      <v-col cols="12" class="text-center">
                                        <v-form
                                          ref="form"
                                          v-model="valid"
                                          :lazy-validation="lazy"
                                        >
                                          <div class="mx-auto input-size">
                                            <v-text-field
                                              v-model="name"
                                              :rules="nameRules"
                                              label="Nombre"
                                              required
                                              name="name"
                                            ></v-text-field>

                                            <v-text-field
                                              v-model="email"
                                              :rules="emailRules"
                                              label="E-mail"
                                              required
                                              name="email"
                                            ></v-text-field>

                                            <!-- <v-textarea
                                                v-model="textArea"
                                                :rules="textAreaRules"
                                                label="Mensaje"
                                                required
                                                name="message"
                                            /> -->
                                          </div>
                                          <div class="btnSize mx-auto">
                                            <v-btn
                                              :disabled="!valid"
                                              :style="{ backgroundImage: createBackgroundString }"
                                              :dark="valid"
                                              rounded
                                              block
                                              class="mt-3"
                                              @click="submitTradingAndHolding"
                                            >
                                             <p style="color: black; margin-top: 15px">Enviar</p>
                                            </v-btn>
                                          </div>
                                        </v-form>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                                <v-row v-if="selectedTableData == 2" class="pb-5">
                                  <v-col cols="12">
                                    <v-row justify="center" align="center">
                                      <v-col cols="12" class="px-5">
                                        <div class="text-container">
                                          <div class="text-center">
                                            <span class="model-title">
                                              ¿ Estas interesado en comenzar a trabajar con
                                              Holding ?</span
                                            >
                                          </div>
                                          <br />
                                          Envianos tus datos a través del siguiente
                                          formulario para que de esta manera podamos
                                          contactarnos contigo y de esta manera poder
                                          ayudarte a conocer como trabajar con holding,
                                          tambien podras recibir informacion de nuestros
                                          cursos en la plataforma de Patreon.
                                        </div>
                                      </v-col>
                                      <v-col cols="12" class="mx-auto text-center">
                                        <v-form
                                          ref="form"
                                          v-model="valid"
                                          :lazy-validation="lazy"
                                        >
                                          <div class="mx-auto input-size">
                                            <v-text-field
                                              v-model="name"
                                              :rules="nameRules"
                                              label="Nombre"
                                              required
                                              name="name"
                                            ></v-text-field>

                                            <v-text-field
                                              v-model="email"
                                              :rules="emailRules"
                                              label="E-mail"
                                              required
                                              name="email"
                                            ></v-text-field>

                                            <!-- <v-textarea
                                                v-model="textArea"
                                                :rules="textAreaRules"
                                                label="Mensaje"
                                                required
                                                name="message"
                                            /> -->
                                          </div>
                                          <div class="btnSize mx-auto">
                                            <v-btn
                                              :disabled="!valid"
                                              color="#c48548"
                                              :dark="valid"
                                              rounded
                                              block
                                              class="mt-3"
                                              @click="submitTradingAndHolding"
                                            >
                                              Enviar
                                            </v-btn>
                                          </div>
                                        </v-form>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                  </v-row>
                  </v-card>
                </v-dialog>
                <v-dialog
                  v-model="confirmDialog"
                  width="800"
                  no-click-animation
                  scrollable
                  content-class="modal-content"
                >
                
                <v-card class="card-body" style="height: 600px;">
                    <v-row >
                      <v-col cols="12">
                        <div style="height: 100%;">
                          <v-row style="height: 100%;">
                            <v-col cols="5" sm="5" md="5" lg="5"  xl="5" style="padding: 0px;">
                              <div class="confirm">
                                
                              </div>
                            </v-col>
                            <v-col cols="7" sm="7" md="7" lg="7"  xl="7" style="padding: 0px;">
                              <div style=" width: 100%; height: 80%;">
                                <v-row>
                                  <v-col cols="12" md="12"  lg="12" xl="12">
                                    <div class="d-flex justify-end align-end" style="padding: 30px 10px 0px 0px;">
                                      <v-btn
                                      @click="confirmDialog = false"
                                        fab
                                        small
                                        style="margin-right: 15px;"
                                        color="#ffffff"
                                      >
                                        <v-icon class="icon-rrss" color="black">mdi-close</v-icon>
                                      </v-btn>
                                    </div>
                                  </v-col>
                                </v-row>
                                <v-row align="center" justify="center" class="row-color">
                                  <v-col
                                    cols="12"
                                    md="12"
                                    lg="12"
                                    xl="12"
                                    class="text-center margin-col d-flex justify-center align-center"
                                    >
                                    <v-img
                                      src="@/assets/img/crown.svg"
                                      max-width="150px"
                                      class="mr-4 imgLg"
                                    />
                                  </v-col>
                                </v-row>
                                <v-row
                                  justify="center"
                                  align="center"
                                  class="pb-5"
                                >
                                  <v-col cols="12">
                                    <v-row justify="center" align="center">
                                      <v-col cols="12" class="px-5">
                                        <div class="text-container d-flex justify-center align-center" style="margin-top: 60px;">
                                         <h1 style="padding: 0px 20px; margin-top: -30px; font-weight: bold;">
                                          Felicitaciones
                                         </h1>
                                        </div>
                                      </v-col>
                                      <v-col cols="12" class="px-5">
                                        <div class="text-container d-flex justify-center align-center">
                                         <p style="padding: 0px 15px; margin-top: -30px; font-size: 20px; text-align: center;">
                                          Ya estas registrado pronto, nos contactaremos contigo.
                                         </p>
                                        </div>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                  </v-row>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </section>
</template>

<script>
import emailjs from "emailjs-com";
export default {
  data() {
    return {
      dialog: false,
      confirmDialog: false,
      selectedTableData: 1,
      active: false,
      valid: true,
      angle: '50',
      color1: '#d8ab3d',
      color2: '#e8bb45',
      name: "",
      nameRules: [
        v => !!v || "Campo obligatorio",
        v => (v && v.length >= 6) || "Se deben ingresar más de 6 caracteres"
      ],
      email: "",
      emailRules: [
        v => !!v || "Campo obligatorio",
        v => /.+@.+\..+/.test(v) || "El email debe ser válido"
      ],
      textArea: "Correo informativo",
      textAreaRules: [
        v => !!v || "Campo obligatorio",
        v => (v && v.length >= 10) || "Mínimo de 10 caracteres"
      ],
      lazy: false
    };
  },
  methods: {
    submitTradingAndHolding() {
      const content = {
        name: this.name,
        email: this.email,
        message: this.textArea
      };
      emailjs
        .send(
          "service_m9y5xup",
          "template_ty020c3",
          content,
          "user_gvJbilDm9ErIC4mqWGvPN"
        )
        .then(
          result => {
            console.log("SUCCESS!", result.text);

          },
          error => {
            console.log("FAILED...", error.text);
          }
        );
      // Reset form field
      this.name = "";
      this.email = "";
      this.textArea = "";
      this.dialog = false;
      this.confirmDialog = true;
      setTimeout(() => {
        this.confirmDialog = false
      }, 2500);
    }
  },
  computed: {
    createBackgroundString() {
      return `linear-gradient(${this.angle}deg, ${this.color1}, ${this.color2})`;
    }
  }
};
</script>

<style>
#references {
  /* background-color: black; */
  background-color: #2b014d;
  background-image: url("~@/assets/img/banner-trader.jpg");
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: center; */
  height: 500px;
  clip-path: inset(0);
}
#references img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#references .bg-opacity {
  position: absolute;
  /* background: rgba(0, 0, 0, 0.65); */
  width: 100%;
  height: 100%;
}
#references .container,
#references .row {
  height: 100%;
}
.margin-up {
  margin-top: 150px !important;
}
.margin-down {
  margin-top: 100px !important;
}
.container {
  overflow: hidden;
}
.modal-content {
  background-color: transparent !important;
}
.card-body {
  border-radius: 35px !important;
  padding: 0 !important;
  overflow-x: hidden;
  overflow-y: auto;
}
.bottom-down {
  margin-bottom: -110px !important;
}
.bottom-down-reponsive {
  margin-top: 20px !important;
  margin-bottom: -60px !important;
}

/* width */
.card-body::-webkit-scrollbar {
  width: 0px;
}

/* Track */
.card-body::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
.card-body::-webkit-scrollbar-thumb {
  background: transparent !important;
}

/* Handle on hover */
.card-body::-webkit-scrollbar-thumb:hover {
  background: transparent !important;
}

/* .row-color {
  padding-top: 10px !important;
  height: 100% !important;
  background-color: #020b0f !important;
} */
.margin-col {
  margin-top: 10px !important;
}
.active {
  background-color: #eabc46;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

}
.image-container {
  padding: 0px !important;
  width: 100% !important;
  z-index: 10000;
}
.text-container {
  padding: 10px !important;
}
.colorTxt {
  color: #ffff;
}
.modal-title {
  cursor: pointer;
  font-size: 20px !important;
}
.tittle-active {
  background-color: #eabc46;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin-top: -20px;
}
.tittle-inactive {
  color: black;
  margin-top: -20px;
}
.input-size {
  margin-top: -40px;
  width: 70% !important;
}
.btnSize {
  width: 200px !important;
}
.model-title {
  /* width: 90% !important; */
  /* text-align: center !important; */
  /* font-weight: bold !important; */
  font-size: 20px !important;
  padding: 5px 20px !important;
}
.holding {
  width: 100%; 
  height: 650px; 
  padding: 50px;
  background-image: url("~@/assets/img/comienza-trading-desk.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.trading {
  width: 100%; 
  height: 650px; 
  padding: 50px;
  background-image: url("~@/assets/img/trabaja-holding-desk.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.confirm {
  width: 100%; 
  height: 650px; 
  padding: 50px;
  background-image: url("~@/assets/img/aviso-despues de enviar-desk.png");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
