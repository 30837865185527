<template>
  <section id="pricing" class="pb-8" style="background-color: #2b014d;">
    <v-container fluid >
      <v-row align="center" justify="center">
        <v-col cols="10" style="background-color: #2b014d;">
          <div style="width: 100%; background-color: #2b014d; border: 0px solid transparent;">
            <div style="width: 100%; display: flex; flex-direction: row; justify-content: center;">
              <div style="width: 50%;">
                <h1 class="text-center font-weight-bold display-1" style="color: #ffc83e; padding: 20px;">
                  ¿Quieres pertenecer o ser un inversor asertivo de Patreon?
                </h1>
              </div>
            </div>
            <v-row class="text-center">
              <v-col class="col-12 col-sm-6 col-md-4" style="padding: 10px 30px 0px 30px">
                <div class="flex-center" style="border: 1px solid #ffc83e; border-radius: 20px; height: 100%;">
                  <v-card-text style="color: white;">
                    <div class="flex-center">
                      <div class="circle1">
                        <div class="circle2">
                          <v-img src="~@/assets/img/plata.png"></v-img>
                        </div>
                      </div>
                    </div>
                    <div class="white--text  text-uppercase text-h5 my-2">
                      PLATA
                    </div>
                    <div class="white--text" style="text-align: start;  padding: 0px 20px 0px 20px;">
                      <p><span style="color: #e8bb45;">●</span> Obtendrás videos exclusivos de “Reinas de Crypto” que no
                      conseguirás en YouTube, son sólo para ti que has decidido
                      iniciarte en este proceso.
                    </p>
                    </div>
                    <div class="white--text" style="text-align: start; margin-top: 10px; padding: 0px 20px 0px 20px;">
                      <p>
                        <span style="color: #e8bb45;">●</span> Kit de inicio para inversores.
                      </p>
                    </div>
                    <div class="white--text" style="text-align: start; margin-top: 10px; padding: 0px 20px 0px 20px;">
                    <p>
                      <span style="color: #e8bb45;">●</span> Como abrir una Crypto Wallet o una billetera de
                      criptomonedas.
                    </p>
                    </div>
                    <div class="white--text" style="text-align: start; margin-top: 10px; padding: 0px 20px 0px 20px;">
                        <p>
                          <span style="color: #e8bb45;">●</span> Como es el funcionamiento de los Exchanges.
                        </p>
                    </div>
                    <div class="white--text" style="text-align: start; margin-top: 10px; padding: 0px 20px 0px 20px;">
                      <p>
                        <span style="color: #e8bb45;">●</span> En este nivel puedes revisar y digerir la información a tu
                      propio ritmo configurado especialmente y de manera
                      práctica para que fácilmente puedas elegir los artículos
                      que te interesan.
                      </p>
                    </div>
                    <div class="mt-4 white--text" >
                      <span style="font-weight: bold; font-size: 30px;">USD $25</span>  <br />
                      Por mes
                    </div>
                    <v-btn
                      v-bind="size"
                      rounded
                      :style="{ backgroundImage: createBackgroundString }"
                      class="mt-6"
                      target="_blank"
                      href="https://www.patreon.com/REINASDECRYPTO"
                    >
                      Comenzar ahora
                  </v-btn>
                  </v-card-text>
                </div>
              </v-col>
              <v-col class="col-12 col-sm-6 col-md-4" style="padding: 10px 30px 0px 30px">
                <div class="flex-center" style="border: 1px solid #ffc83e; border-radius: 20px;">
                  <v-card-text>
                    <div class="flex-center">
                      <div class="circle1">
                        <div class="circle2">
                          <v-img src="~@/assets/img/oro.png" />
                        </div>
                      </div>
                    </div>
                    <div class="white--text  text-uppercase text-h5 my-2">
                      Oro
                    </div>
                    <div class="white--text" style="text-align: start;  padding: 0px 20px 0px 20px;">
                      <p>
                        <span style="color: #e8bb45;">●</span> Te proporcionaremos un video mensual de nuestra
                      criptomoneda o proyecto en el cual confiamos y que
                      pertenece a nuestra billetera.
                      </p>
                    </div>
                    <div class="white--text" style="text-align: start; margin-top: 10px; padding: 0px 20px 0px 20px;">
                      <p>
                        <span style="color: #e8bb45;">●</span> En este nivel puedes revisar y digerir la información a tu
                      propio ritmo configurado especialmente y de manera
                      práctica para que fácilmente puedas elegir los artículos
                      que te interesan.
                      </p>
                    </div>
                    <div class="white--text" style="text-align: start; margin-top: 10px; padding: 0px 20px 0px 20px;">
                      <p>
                        <span style="color: #e8bb45;">●</span> Actualizaciones mensuales del portafolio de "Reinas de
                        Crypto".
                      </p>
                    </div>
                    <div class="white--text" style="text-align: start; margin-top: 10px; padding: 0px 20px 0px 20px;">
                      <p>
                        <span style="color: #e8bb45;">●</span> Poder de voto en encuestas exclusivas sobre temas para
                       futuros videos.
                      </p>
                    </div>

                    <div class="mt-8 white--text" >
                      <span style="font-weight: bold; font-size: 30px;">USD $75</span>  <br />
                      Por mes
                    </div>
                    <v-btn
                      v-bind="size"
                      rounded
                      :style="{ backgroundImage: createBackgroundString }"
                      class="mt-6 mb-5"
                      target="_blank"
                      href="https://www.patreon.com/REINASDECRYPTO"
                    >
                      Comenzar ahora
                  </v-btn>
                  </v-card-text>
                </div>
              </v-col>
              <v-col class="col-12 col-md-4" style="padding: 10px 30px 0px 30px">
                <div class="flex-center" style="border: 1px solid #ffc83e; border-radius: 20px;">
                  <v-card-text>
                    <div class="flex-center">
                      <div class="circle1">
                        <div class="circle2">
                          <v-img src="~@/assets/img/platinum.png" />
                        </div>
                      </div>
                    </div>
                    <div class="white--text  text-uppercase text-h5 my-2">
                      Platino
                    </div>
                    <div class="white--text" style="text-align: start;  padding: 0px 20px 0px 20px;">
                      <p>
                        <span style="color: #e8bb45;">●</span> Si eres un inversor a largo plazo y quieres hacer las
                      cosas correctas desde cero, con el nivel Crypto Platino
                      tendrás consejos personalizados sobre el mundo.
                      </p>
                    </div>
                    <div class="white--text" style="text-align: start; margin-top: 10px; padding: 0px 20px 0px 20px;">
                      <p>
                        <span style="color: #e8bb45;">●</span> Obtendrás todo lo que obtienen los miembros de los niveles
                      Plata y Oro, y además tendrás sesión en vivo de preguntas,
                      respuestas y análisis.
                      </p>
                    </div>
                    <div class="white--text" style="text-align: start; margin-top: 10px; padding: 0px 20px 0px 20px;">
                      <p>
                        <span style="color: #e8bb45;">●</span>  Nuestro sistema está diseñado solo para personas que estén
                      comprometido con su vida y bienestar económico.
                      </p>
                    </div>
                    <div class="white--text" style="text-align: start; margin-top: 10px; padding: 0px 20px 0px 20px;">
                      <p>
                        <span style="color: #e8bb45;">●</span>  Te convertirás en un inversor de alto nivel de rendimiento
                      de tus finanzas y estaremos allí junto a ti para ver tu
                      exitoso camino y felicitarte..
                      </p>
                    </div>
                    <div class="mt-8 white--text" >
                     <span style="font-weight: bold; font-size: 30px;">USD $999</span> <br />
                      Por mes
                    </div>
                    <v-btn
                      v-bind="size"
                      rounded
                      :style="{ backgroundImage: createBackgroundString }"
                      class="mt-6"
                      target="_blank"
                      href="https://www.patreon.com/REINASDECRYPTO"
                    >
                      Comenzar ahora
                  </v-btn>
                  </v-card-text>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  data: () => ({
    angle: '50',
      color1: '#d8ab3d',
      color2: '#e8bb45'
  }),
  computed: {
    size() {
      const size = { md: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    createBackgroundString() {
      return `linear-gradient(${this.angle}deg, ${this.color1}, ${this.color2})`;
    }
  }
};
</script>

<style lang="scss" scoped>
$main_color: #283e79;
ul {
  font-size: 13px;
  line-height: 1.5em;
  margin: 5px 0 15px;
  padding: 0;
  li {
    list-style: none;
    position: relative;
    padding: 0 0 0 20px;
  }
  li {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 5px;
      width: 10px;
      height: 10px;
      background-color: $main_color;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-eeborder-radius: 50%;
    }
  }
}
</style>

<style scoped>
.header {
  background-color: #283e79;
  color: white;
}
.circle1 {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle2 {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.svg-border-rounded svg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #f4f7f5;
  z-index: -1;
}
#pricing {
  z-index: 0;
}
.content {
  z-index: 1;
}
svg {
  overflow: hidden;
}
section {
  position: relative;
}
</style>
