<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      dark
      src="@/assets/img/Drawer.png"
    >
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <!-- <img src="@/assets/img/Logo.jpeg" alt="Logo" /> -->
          </v-list-item-avatar>
          <v-list-item-content> </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list dense>
        <v-list-item
          v-for="([icon, text, link], i) in items"
          :key="i"
          link
          @click="$vuetify.goTo(link)"
        >
          <v-list-item-icon class="justify-center">
            <v-icon color="white">{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              class="subtitile-1 font-weight-bold white--text"
              >{{ text }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item href="/cart">
          <v-list-item-icon class="justify-center">
            <v-icon color="white">fas fa-shopping-cart</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="subtitile-1 font-weight-bold white--text"
              >Carrito</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      :color="color"
      :flat="flat"
      dark
      class="px-15"
      :class="{ expand: flat }"
    >
      <v-toolbar-title>
        <div style="display: flex; flex-direction: row; justify-content: center; align-items: center;">
        <v-img src="@/assets/img/NewLogo.png" max-width="50px" height="50px" />
        
        
        <p  v-if="!$vuetify.breakpoint.smAndDown" style="margin-top: 15px; margin-left: 20px; color: #ffc83e; font-weight: bold;">REINAS DE CRIPTO</p>
      </div>
      </v-toolbar-title>
      <v-spacer />
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="mr-4"
        v-if="isXs"
      />
      <div v-else>
        <div v-if="currentRouteName() === 'Home'">
          <v-btn text @click="$vuetify.goTo('#home')">
            <span class="mr-2">Principal</span>
          </v-btn>
          <v-btn text @click="$vuetify.goTo('#about')">
            <span class="mr-2">¿Quienes somos?</span>
          </v-btn>
          <v-btn text @click="$vuetify.goTo('#references')">
            <span class="mr-2">Empieza Ya!</span>
          </v-btn>
          <v-btn text @click="$vuetify.goTo('#pricing')">
            <span class="mr-2">Cursos</span>
          </v-btn>
          <!-- <v-btn text @click="$vuetify.goTo('#donations')">
            <span class="mr-2">Donaciones</span>
          </v-btn> -->
          <v-btn rounded outlined text @click="$vuetify.goTo('#contact')">
            <span class="mr-2">Contáctenos</span>
          </v-btn>
          <!-- <v-btn text color="#272727" href="/cart">
            <v-icon color="white">fas fa-shopping-cart</v-icon>
          </v-btn> -->
        </div>
        <div v-else>
          <v-btn text  href="/">
            <span class="mr-2">Regresar al Inicio</span>
          </v-btn>
        </div>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data: () => ({
    drawer: null,
    isXs: false,
    items: [
      ["mdi-home-outline", "Principal", "#home"],
      ["mdi-information-outline", "¿Quienes somos?", "#about"],
      ["mdi-download-box-outline", "Empieza Ya!", "#references"],
      ["mdi-currency-usd", "Cursos", "#pricing"],
      ["mdi-email-outline", "Contáctenos", "#contact"]
    ]
  }),
  props: {
    color: String,
    flat: Boolean
  },
  methods: {
    onResize() {
      this.isXs = window.innerWidth < 850;
    },
    currentRouteName() {
      console.log(this.$route.name);
        return this.$route.name;
    }
  },
  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    this.currentRouteName()
  },
  // computed: {
  //   currentRouteName() {
  //     console.log(this.$route.name);
  //       return this.$route.name;
  //   }
  // }
};
</script>

<style scoped>
.v-toolbar {
  transition: 0.6s;
}
.expand {
  height: 80px !important;
  padding-top: 10px;
}
</style>
