import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./views/plugins/vuetify";
import VueYouTubeEmbed from "vue-youtube-embed";
import Gapi from "vue-googleapis";

Vue.use(VueYouTubeEmbed);
Vue.use(Gapi, {
  apiKey: "AIzaSyBtXniZV2k8DSKSi_gHkldpXbuvq0-KrAY",
  clientId:
    "201768808771-sjpahnt95oqnrr3daohlft1teujjes6s.apps.googleusercontent.com",
  // discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest"],
  // scope:
  //   "https://mail.google.com/ " +
  //   "https://www.googleapis.com/auth/gmail.readonly " +
  //   "https://www.googleapis.com/auth/gmail.send " +
  //   "https://www.googleapis.com/auth/gmail.modify"
  discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest"],
  scope: "https://www.googleapis.com/auth/youtube.readonly"
});
import VueClipboard from "vue-clipboard2";

Vue.use(VueYouTubeEmbed);
Vue.use(VueClipboard);
Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
