<template>
  <div>
    <!-- <navigation /> -->
    <v-main class="pt-0">
      <home />
      <about />
      <models />
      <homeCart />
      <download />
      <div style="background-color:  #2b014d; height: 100px;"></div>
      <donations />
      <pricing />
      <contact />
      <!-- <customFooter /> -->
    </v-main>
    <v-scale-transition>
      <v-btn
        fab
        v-scroll="onScroll"
        dark
        fixed
        bottom
        right
        small
        color="secondary"
        @click="toTop"
      >
        <v-icon color="white">mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>
    <!-- <v-speed-dial
      v-model="fab"
      left
      bottom
      :direction="$vuetify.breakpoint.smAndDown ? 'top' : 'right'"
      :open-on-hover="false"
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="#989797" dark fab small>
          <v-icon color="white">fas fa-at</v-icon>
        </v-btn>
      </template>
      <v-btn fab small icon target="_blank" href="https://wa.link/6fed0c">
        <v-img
          src="@/assets/img/Whatsapp.png"
          :width="$vuetify.breakpoint.smAndDown ? '40' : '60'"
          :height="$vuetify.breakpoint.smAndDown ? '40' : '60'"
          class="mt-4"
        />

      </v-btn>
      <v-btn
        target="_blank"
        href="https://www.facebook.com/reinas.de.crypto"
        fab
        small
        icon
      >
        <v-img
          src="@/assets/img/Facebook.png"
          :width="$vuetify.breakpoint.smAndDown ? '40' : '60'"
          :height="$vuetify.breakpoint.smAndDown ? '40' : '60'"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : 'mt-4'"
        />

      </v-btn>
      <v-btn
        target="_blank"
        href="https://www.instagram.com/reinasdecrypto/"
        fab
        small
        icon
      >
        <v-img
          src="@/assets/img/Instagram.png"
          :width="$vuetify.breakpoint.smAndDown ? '40' : '60'"
          :height="$vuetify.breakpoint.smAndDown ? '40' : '60'"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : 'mt-4'"
        />
      </v-btn>
      <v-btn
        target="_blank"
        href="https://t.me/joinchat/Z9x8nrxEhb0yNjBh"
        fab
        small
        icon
      >
        <v-img
          src="@/assets/img/Telegram.png"
          :width="$vuetify.breakpoint.smAndDown ? '40' : '60'"
          :height="$vuetify.breakpoint.smAndDown ? '40' : '60'"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : 'mt-4'"
        />
      </v-btn>
      <v-btn
        target="_blank"
        href="https://twitter.com/reinasdecrypto1?s=08"
        fab
        small
        icon
      >
        <v-img
          src="@/assets/img/Twitter.png"
          :width="$vuetify.breakpoint.smAndDown ? '40' : '60'"
          :height="$vuetify.breakpoint.smAndDown ? '40' : '60'"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : 'mt-4'"
        />
      </v-btn>
      <v-btn
        target="_blank"
        href="https://www.youtube.com/channel/UC53ovR3xPrnvWDrkumRX9iw"
        fab
        small
        icon
      >
        <v-img
          src="@/assets/img/Youtube.png"
          :width="$vuetify.breakpoint.smAndDown ? '40' : '60'"
          :height="$vuetify.breakpoint.smAndDown ? '40' : '60'"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : 'mt-4'"
        />
      </v-btn>
    </v-speed-dial> -->
  </div>
</template>

<script>
import home from "../components/HomeComponent.vue";
import about from "../components/AboutSection.vue";
import download from "../components/ReferenceSection.vue";
import pricing from "../components/PricingSection.vue";
import contact from "../components/ContactSection.vue";
import donations from "../components/Donations.components.vue";
import homeCart from '../components/HomeCart.vue'
import models from '../components/Models.vue'
// import customFooter from "../components/Footer.component.vue"
export default {
  name: "Home",
  title() {
    return "Crypto";
  },

  components: {
    // navigation,
    home,
    about,
    download,
    pricing,
    donations,
    contact,
    homeCart,
    models,
    // customFooter
  },

  data: () => ({
    fab: null,
    color: "",
    flat: null,
    hover: false
    // transition: 'slide-y-reverse-transition',
  }),
  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }
  },
  watch: {
    fab(value) {
      if (value) {
        this.color = "secondary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    }
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    }
  }
};
</script>

<style scoped>
.social {
  width: 70px;
  height: 150px;
  text-align: center;
  position: fixed !important;
  top: 40%;
  left: 5px;
}
.v-speed-dial {
  position: absolute;
}
/* .v-main {
  background-image: url("../assets/img/bgMain.png");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
} */
</style>
