<template>
  <section id="home">
    <div
      :class="$vuetify.breakpoint.smAndDown
        ? 'parallax-container-responsive'
        : 'parallax-container'"
    >
    <v-btn
    v-if="!$vuetify.breakpoint.smAndDown"
        v-bind="size"
        style="cursor: pointer; position: absolute; bottom: 135px; left: 190px; width: 320px; height: 80px; background-color: transparent;"
        rounded
        class="mt-6"
        target="_blank"
        href="https://www.youtube.com/c/ReinasdeCrypto"
      >
    </v-btn>
    <v-btn
    v-if="$vuetify.breakpoint.smOnly"
        v-bind="size"
        style="cursor: pointer; position: absolute; bottom: 135px; left: 50px; width: 180px; height: 50px; background-color: transparent;"
        rounded
        class="mt-6"
        target="_blank"
        href="https://www.youtube.com/c/ReinasdeCrypto"
      >
    </v-btn>
    <v-btn
    v-if="$vuetify.breakpoint.xsOnly"
        v-bind="size"
        style="cursor: pointer; position: absolute; bottom: 135px; left: 50px; width:190px; height: 50px; background-color: transparent;"
        rounded
        class="mt-6"
        target="_blank"
        href="https://www.youtube.com/c/ReinasdeCrypto"
      >
    </v-btn>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      videoId: "OHsvmddT2iI"
    };
  },
  computed: {
    size() {
      const size = { md: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
  }
};
</script>

<style lang="scss">
.row-center {
  margin-top: 290px !important;
}
.multi-align {
  margin-left: -110px !important;
}
.margin-text {
  margin-top: -50px !important;
  margin-left: -50px !important;
}
.margin-text-responsive {
  margin-top: 200px !important;
}
.parallax-container {
  background-image: url("~@/assets/img/Home.jpg");
  // background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;

  height: 750px;
}
.parallax-container-responsive {
  background-image: url("~@/assets/img/Banner-principal01-mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  // background-position: center;
  padding-top: 200px !important;
  height: 480px;
  margin-bottom: -10px;
}
.circle {
  stroke: white;
  stroke-dasharray: 650;
  stroke-dashoffset: 650;
  -webkit-transition: all 0.5s ease-in-out;
  opacity: 0.3;
}
.playBut {
  /*  border: 1px solid red;*/
  display: inline-block;
  -webkit-transition: all 0.5s ease;
  .triangle {
    -webkit-transition: all 0.7s ease-in-out;
    stroke-dasharray: 240;
    stroke-dashoffset: 480;
    stroke: white;
    transform: translateY(0);
  }
  &:hover {
    .triangle {
      stroke-dashoffset: 0;
      opacity: 1;
      stroke: white;
      animation: nudge 0.7s ease-in-out;
      @keyframes nudge {
        0% {
          transform: translateX(0);
        }
        30% {
          transform: translateX(-5px);
        }
        50% {
          transform: translateX(5px);
        }
        70% {
          transform: translateX(-2px);
        }
        100% {
          transform: translateX(0);
        }
      }
    }
    .circle {
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
}
</style>

<style>
.margin-align {
  margin-top: -10px !important;
  color: white;
}
.btn-play {
  transition: 0.2s;
}
.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}
#hero {
  z-index: 0;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}
.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}
.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}
.card h1 {
  margin-bottom: 10px;
}
.zoom-efect {
  transform: scale(1.1);
}
.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
.tittleColor {
  color: #c48548;
}
.subTittleColor margin-align {
  color: white;
}
</style>

<style>
section {
  position: relative;
}
</style>
