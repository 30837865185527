<template>
  <section id="contact" class="pb-8" style="background-color: #2b014d; padding-top: 30px;">
    <v-container fluid >
      <v-row align="center" justify="center">
        <v-col cols="10" style="background-color: #2b014d;">
          <div style="width: 100%; background-color: #2b014d; border: 0px solid transparent;">
            <div style="width: 100%; display: flex; flex-direction: row; justify-content: center;">
              <div style="width: 50%;">
                <h1 class="text-center font-weight-bold display-1" style="color: white; padding: 20px;">
                 Conoce los últimos <br>
                 videos de nuestro canal
                </h1>
              </div>
            </div>
            <v-row class="text-center">
              <v-col class="col-12 col-sm-6 col-md-4" style="padding: 10px 30px 0px 30px">
                <div class="flex-center" style="border: 1px solid #ffc83e; border-radius: 20px;  max-height: 600px;">
                  <v-card-text style="color: white;">
                    <youtube player-width="100%" style="border-radius: 20px;" :video-id="firstVideoId" @ready="ready"></youtube>
                    <div class="white--text" style="text-align: start;  padding: 0px 20px 0px 20px; margin-top: 10px; font-size: 18px;">
                      <p>{{this.firstVideoTitle}}
                    </p>
                    </div>
                    <div style="display: flex; flex-direction: row; justify-content: start; align-content: center; padding: 0px 10px">
                      <v-btn
                        v-bind="size()"
                        color="#c48548"
                        rounded
                        outlined
                        class="mt-6"
                        target="_blank"
                        style="margin-bottom: 20px;"
                        v-bind:href="this.firstVideoLink"
                      >
                       Ver video ->
                    </v-btn>
                  </div>
                  </v-card-text>
                </div>
              </v-col>
              <v-col class="col-12 col-sm-6 col-md-4" style="padding: 10px 30px 0px 30px">
                <div class="flex-center" style="border: 1px solid #ffc83e; border-radius: 20px; max-height: 600px;">
                  <v-card-text>
                    <youtube player-width="100%" style="border-radius: 20px;" :video-id="secondVideoID" @ready="ready"></youtube>
                    <div class="white--text" style="text-align: start;  padding: 0px 20px 0px 20px; margin-top: 10px; font-size: 18px;">
                      <p>{{this.secondVideoTitle}}
                    </p>
                    </div>
                    <div style="display: flex; flex-direction: row; justify-content: start; align-content: center; padding: 10px 10px; margin-top: 15px;">
                      <v-btn
                        v-bind="size()"
                        color="#c48548"
                        rounded
                        outlined
                        class="mt-6"
                        target="_blank"
                        style="margin-bottom: 20px; margin-top: 30px;"
                        v-bind:href="this.secondVideoLink"
                      >
                       Ver video ->
                    </v-btn>
                  </div>
                  </v-card-text>
                </div>
              </v-col>
              <v-col class="col-12 col-md-4" style="padding: 10px 30px 0px 30px">
                <div class="flex-center" style="border: 1px solid #ffc83e; border-radius: 20px;">
                  <v-card-text>
                    <youtube player-width="100%" style="border-radius: 20px;" :video-id="thirdVideoID" @ready="ready"></youtube>
                    <div class="white--text" style="text-align: start;  padding: 0px 20px 0px 20px; margin-top: 10px; font-size: 18px;">
                      <p>{{this.thirdVideoTitle}}
                    </p>
                    </div>
                    <div style="display: flex; flex-direction: row; justify-content: start; align-content: center; padding: 10px 10px; margin-top: -20px;">
                      <v-btn
                        v-bind="size()"
                        color="#c48548"
                        rounded
                        outlined
                        class="mt-6"
                        target="_blank"
                        style="margin-bottom: 20px; margin-top: 30px;"
                        v-bind:href="this.thirdVideoLink"
                      >
                       Ver video ->
                    </v-btn>
                  </div>
                  </v-card-text>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <div style="width: 100%; display: flex; flex-direction: row; justify-content: center; margin-top: 40px;">
                <v-col class="col-12 col-md-12" style="padding: 10px 30px 0px 30px">
                  <div style="width: 100%;">
                    <h1 class="text-center font-weight-bold display-1" style="color: #ffc83e; padding: 20px;">
                    Subscribete a nuestro newsletter
                    </h1>
                 </div>
                </v-col>
            </div>
            </v-row>
            <v-row style="padding: 0px; margin-top: -30px;">
              <div style="width: 100%; display: flex; flex-direction: row; justify-content: center;">
                <v-col class="col-12 col-md-12 p-0">
                    <p class="text-center" style="color: white; padding: 20px;">
                   Recibe todas las novedades del mercado y promociones de Reinas de Crypto
                    </p>
                </v-col>
              </div>
            </v-row>
            <v-row style="margin-top: -25px;">
              <v-col class="col-12 col-md-12 p-0 d-flex justify-center align-center">
              <div v-if="!$vuetify.breakpoint.smAndDown" style="width: 50%;" class="d-flex justify-center align-center">
                <v-text-field  v-model="email" placeholder="ejemplo@gmail.com" outlined  style="width: 100%; background-color: white; height: 55px; border-top-left-radius: 20px; border-bottom-left-radius: 20px; border-top-right-radius: 0px; border-bottom-right-radius: 0px; border: 0px solid transparent"></v-text-field>
                <v-btn @click="submit" class="d-flex justify-center align-center" color="#ffc83e" style="height: 55px; border-top-left-radius: 0px; border-bottom-left-radius: 0px; border-bottom-right-radius: 20px; border-top-right-radius: 20px; border-left-color: white;"> 
                  <p style="color: black; margin-top: 15px;">Enviar -></p>  
                </v-btn>
              </div>
              <div v-if="$vuetify.breakpoint.smAndDown" style="width: 300px;" class="d-flex justify-center align-center">
                <v-text-field  v-model="email" placeholder="ejemplo@gmail.com" outlined  style="width: 50%; background-color: white; height: 55px; border-top-left-radius: 20px; border-bottom-left-radius: 20px; border-top-right-radius: 0px; border-bottom-right-radius: 0px; border: 0px solid transparent"></v-text-field>
                <v-btn @click="submit" class="d-flex justify-center align-center" color="#ffc83e" style="height: 55px; border-top-left-radius: 0px; border-bottom-left-radius: 0px; border-bottom-right-radius: 20px; border-top-right-radius: 20px; border-left-color: white;"> 
                  <p style="color: black; margin-top: 15px;">Enviar -></p>  
                </v-btn>
              </div>
              </v-col>
            </v-row>
            <v-dialog
                  v-model="confirmDialog"
                  width="800"
                  no-click-animation
                  scrollable
                  content-class="modal-content"
                >
                
                <v-card class="card-body" style="height: 600px;">
                    <v-row >
                      <v-col cols="12">
                        <div style="height: 100%;">
                          <v-row style="height: 100%;">
                            <v-col cols="5" sm="5" md="5" lg="5"  xl="5" style="padding: 0px;">
                              <div class="confirm">
                                
                              </div>
                            </v-col>
                            <v-col cols="7" sm="7" md="7" lg="7"  xl="7" style="padding: 0px;">
                              <div style=" width: 100%; height: 80%;">
                                <v-row>
                                  <v-col cols="12" md="12"  lg="12" xl="12">
                                    <div class="d-flex justify-end align-end" style="padding: 30px 10px 0px 0px;">
                                      <v-btn
                                      @click="confirmDialog = false"
                                        fab
                                        small
                                        style="margin-right: 15px;"
                                        color="#ffffff"
                                      >
                                        <v-icon class="icon-rrss" color="black">mdi-close</v-icon>
                                      </v-btn>
                                    </div>
                                  </v-col>
                                </v-row>
                                <v-row align="center" justify="center" class="row-color">
                                  <v-col
                                    cols="12"
                                    md="12"
                                    lg="12"
                                    xl="12"
                                    class="text-center margin-col d-flex justify-center align-center"
                                    >
                                    <v-img
                                      src="@/assets/img/crown.svg"
                                      max-width="150px"
                                      class="mr-4 imgLg"
                                    />
                                  </v-col>
                                </v-row>
                                <v-row
                                  justify="center"
                                  align="center"
                                  class="pb-5"
                                >
                                  <v-col cols="12">
                                    <v-row justify="center" align="center">
                                      <v-col cols="12" class="px-5">
                                        <div class="text-container d-flex justify-center align-center" style="margin-top: 60px;">
                                         <h1 style="padding: 0px 20px; margin-top: -30px; font-weight: bold;">
                                          Felicitaciones
                                         </h1>
                                        </div>
                                      </v-col>
                                      <v-col cols="12" class="px-5">
                                        <div class="text-container d-flex justify-center align-center">
                                         <p style="padding: 0px 15px; margin-top: -30px; font-size: 20px; text-align: center;">
                                          Ya estas registrado pronto, nos contactaremos contigo.
                                         </p>
                                        </div>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                  </v-row>
                  </v-card>
            </v-dialog>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import emailjs from "emailjs-com";
export default {
  data: () => ({
    firstVideoId: "yzbhg2QLATM",
    firstVideoTitle: '¡¡¡Gana hasta $100 Septiembre con Reinas de Crypto!!!',
    firstVideoLink: 'https://www.youtube.com/watch?v=yzbhg2QLATM',
    secondVideoID: "56FmNru-AXI",
    secondVideoTitle: 'Degrain (DGRN)',
    secondVideoLink: 'https://www.youtube.com/watch?v=56FmNru-AXI',
    thirdVideoID: "q-EM1JUZtkw",
    thirdVideoTitle: 'Qué está sucediendo en el mercado, consideraciones The Merge',
    thirdVideoLink: 'https://www.youtube.com/watch?v=q-EM1JUZtkw',
    valid: true,
    confirmDialog: false,
    name: "Usuario interesado en info",
    email: "",
    textArea: "Correo informativo",
  }),
  methods: {
    submit() {
      const content = {
        name: this.name,
        email: this.email,
        message: this.textArea
      };
      emailjs
        .send(
          "service_m9y5xup",
          "template_ty020c3",
          content,
          "user_gvJbilDm9ErIC4mqWGvPN"
        )
        .then(
          result => {
            console.log("SUCCESS!", result.text);
          },
          error => {
            console.log("FAILED...", error.text);
          }
        );
      // Reset form field
      this.name = "";
      this.email = "";
      this.textArea = "";
      this.$vuetify.goTo(0);
    },
    ready(event) {
      this.player = event.target;
    },
    stop() {
      this.player.stopVideo();
    },
    pause() {
      this.player.pauseVideo();
    },
    size() {
      const size = { md: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    }
  }
};
</script>

<style scoped>
/* #contact {
  background-image: url("~@/assets/img/Contacto.png");
  background-size: cover;
} */
.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}
.tittleSize {
  font-size: 60px !important;
  color: white;
}
.contentSize {
  font-size: 25px !important;
  color: white;
}
.btnSize {
  width: 200px !important;
}
.btnColor {
  color: #ffffff !important;
}
.margin-responsive-about {
  margin-left: -20px !important;
}
.v-card__subtitle, .v-card__text, .v-card__title {
    padding: 5px;
    border-radius: 10px;
}
.confirm {
  width: 100%; 
  height: 650px; 
  padding: 50px;
  background-image: url("~@/assets/img/aviso-despues de enviar-desk.png");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
