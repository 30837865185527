<template>
  <section id="about" style="padding-bottom: 100px;">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="center">
            <v-col cols="12" md="7">
              <h1 class="font-weight-light display-2 text-center white--text">
                ¿Quienes somos?
              </h1>
              <v-row align-content="center" justify="center">
                <v-col cols="12" class="d-flex align-center">
                  <v-img
                    src="@/assets/img/coinsIcon.svg"
                    max-width="60px"
                    class="mr-5 imgLg"
                  />
                  <p class="text-justify mt-2  white--text">
                    -Somos miembros de una de las más acreditadas plataformas dedicados exclusivamente a hacer periódicamente análisis de los mercados en las distintas criptomonedas y aunque <span class="font-weight-bold"> no somos una empresa de asesoría financiera</span> todos nuestros seguidores encuentran en nosotros exitosos resultados.
                  </p>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
                  <v-img
                    src="@/assets/img/crown.svg"
                    max-width="60px"
                    class="mr-4 imgLg"
                  />
                  <p class="text-justify  white--text">
                    <span class="font-weight-bold  white--text">-Reina de Cripto</span> es un extraordinario aliado para todos los arriesgados que quieren obtener ganancias en el mundo moderno, que no es otro que el mundo de las criptomonedas, muchos han podido, tú también podrás, inténtalo, tienes mucho que ganar, sólo mira nuestros análisis.
                  </p>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
                  <v-img
                    src="@/assets/img/stadistics.svg"
                    max-width="60px"
                    class="mr-4 imgLg"
                  />
                  <p class="text-justify  white--text">
                    -Si estás inconforme con tus resultados de ingresos, si quieres algo más que te permita una mejor calidad de vida, deberías seguirnos;  quienes lo han hecho están obteniendo excelentes resultados,  síguenos y con gusto compartiremos contigo nuestra experiencia.
                  </p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="5" class="d-none d-md-flex">
              <v-img
                src="@/assets/img/NewLogo.png"
                class="d-block ml-auto mr-auto"
                max-width="400px"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid id="features" class="mt-2">
      <v-row align="center" justify="center" class="py-5">
        <v-col
              cols="12"
              sm="12"
              class="text-center"
            >
          <h1 class="font-weight-light display-2 text-center mb-5 white--text">
            Temas principales
          </h1>
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="9" style="height: 600px; margin-left: 150px;">
          <v-row align="center" justify="space-around" style="height: 100%; width: 100%;">
            <v-col
              cols="12"
              sm="4"
              class="text-center"
              style="  height: 100%; padding: 0px 10px;"
            >
           <div class="left-box">
           <div style="height: 100%;" class="d-flex justify-center align-end">
            <div class="d-flex flex-column">
              <p style="color: white; font-weight: bold; font-size: 25px;">Principales Exchange</p>
                <div style="display: flex; flex-direction: row; justify-content: center; align-items: center;">
                  <v-img
                        contain
                        src="../assets/img/binance.png"
                        style="border-radius: 15px; width: 60px; height: 60px; resize: none; margin-left: 10px;"
                      />
                   <p  style="color: white; font-size: 20px; margin-right: 20px;"> <a style="color: white; text-decoration: underline;" :href="this.link1">Binance</a> -></p>

                </div>
                <div  style="display: flex; flex-direction: row; justify-content: center; align-items: center;">
                  <v-img
                          contain
                          src="../assets/img/ascend.png"
                          style="border-radius: 15px; width: 60px; height: 60px; resize: none;"
                        />
                <p  style="color: white; font-size: 20px;"> <a style="color: white; text-decoration: underline;" :href="this.link2">AscendEX</a> -></p>
                </div>
                <div  style="display: flex; flex-direction: row; justify-content: center; align-items: center;">
                  <v-img
                          contain
                          src="../assets/img/cukoin.png"
                          style="border-radius: 15px; width: 60px; height: 60px; resize: none; "
                        />
                <p  style="color: white; font-size: 20px; margin-right: 25px"> <a style="color: white; text-decoration: underline;" :href="this.link3">Kucoin</a> -></p>
                </div>
            </div>
           </div>
           </div>
            </v-col>
            <v-col
              cols="12"
              sm="8"
              class="text-center"
              style="height: 100%;"
            >
            <v-row justify="space-around" style=" height: 100%;">
              <v-col
              cols="12"
              sm="12"
              class="text-center"
              style="border-radius: 15px; height: 50%; padding: 10px 10px; ri"
            >
              <div class="right-up-card">
                <div class="d-flex justify-end align-center" style="height: 100%; width: 100%; padding: 10px">
                  <div class="d-flex flex-column" style="margin-right: 40px;">
                    <p style="color: white; font-weight: bold; font-size: 25px;">Todo lo que <br> debes saber del <br> mundo crypto</p>
                    <v-btn
                      v-bind="size"
                      rounded
                      :style="{ backgroundImage: createBackgroundString }"
                      target="_blank"
                    >
                      Visitar blog
                  </v-btn>
                  </div>
                </div>
              </div>
              </v-col>
              <v-col
              cols="12"
              sm="12"
              class="text-center"
              style="border-radius: 15px; height: 50%;"
              >
                <div class="right-down-card">
                  <v-img
                      src="../assets/img/right-down-card.gif"
                      style="border-radius: 15px; width: 100%; height: 100%;"
                    />
                </div>
              </v-col>
            </v-row>
            </v-col>
          </v-row>
        </v-col>
        <div v-if="$vuetify.breakpoint.smAndDown" style="height: 600px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
          <v-row>
            <div class="left-box-respon">
              <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                  <v-img
                        contain
                        src="../assets/img/binance.png"
                        style="border-radius: 15px; width: 60px; height: 60px; resize: none; margin-left: 10px;"
                      />
                   <p  style="color: white; font-size: 20px; margin-left: 20px;"> <a style="color: white; text-decoration: underline;" :href="this.link1">Binance</a> -></p>

                </div>
                <div  style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                  <v-img
                          contain
                          src="../assets/img/ascend.png"
                          style="border-radius: 15px; width: 60px; height: 60px; resize: none;"
                        />
                <p  style="color: white; font-size: 20px; margin-left: 10px;"> <a style="color: white; text-decoration: underline;" :href="this.link2">AscendEX</a> -></p>
                </div>
                <div  style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                  <v-img
                          contain
                          src="../assets/img/cukoin.png"
                          style="border-radius: 15px; width: 60px; height: 60px; resize: none; "
                        />
                <p  style="color: white; font-size: 20px; margin-left: 20px;"> <a style="color: white; text-decoration: underline;" :href="this.link3">Kucoin</a> -></p>
                </div>
            </div>
            <div class="ritgth-up-box-respon">
              <div class="d-flex justify-end align-center" style="height: 100%; width: 100%; padding: 10px">
                  <div class="d-flex flex-column" style="margin-right: 40px;">
                    <p style="color: white; font-weight: bold; font-size: 25px;">Todo lo que <br> debes saber del <br> mundo crypto</p>
                    <v-btn
                      v-bind="size"
                      rounded
                      :style="{ backgroundImage: createBackgroundString }"
                      target="_blank"
                    >
                      Visitar blog
                  </v-btn>
                  </div>
                </div>
            </div>
            <div style="width: 500px; height: 250px; border-radius: 15px;; margin-left: 70px; margin-top: 20px;">
             
                  <v-img
                      src="../assets/img/temas-principales01-concurso-mobile.gif"
                      style="border-radius: 15px; width: 100%; height: 100%;"
                    />
            </div>
          </v-row>
        </div>
      </v-row>
      
    </v-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
          link1: "https://accounts.binance.com/en/register?ref=41292687",
          link2: "https://ascendex.com/en-us/register?inviteCode=UEVXHRYPT",
          link3: "https://www.kucoin.com/ucenter/signup?rcode=r3B5XQM",
          angle: '50',
          color1: '#d8ab3d',
          color2: '#e8bb45'
        };
  },
  computed: {
    size() {
      const size = { md: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    createBackgroundString() {
      return `linear-gradient(${this.angle}deg, ${this.color1}, ${this.color2})`;
    }
  }
};
</script>

<style scoped>
#about {
  background-color: black;
}
.imgLg {
  margin-left: -5px;
}

.left-box{
  background-image: url("~@/assets/img/left-card.png");
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  /* background-size: contain; */
  /* background-position: center; */
  border-radius: 15px; 
  width: 100%;
  height: 100%; 
  /* padding: 0px 10px; */
}

.left-box-respon {
  background-image: url("~@/assets/img/temas-principales01-mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 500px; 
  /* height: 300px;  */
  margin-left: 70px;
  border-radius: 15px; 
  margin-top: 30px;
  padding: 5px;
}

.right-up-card{
  background-image: url("~@/assets/img/right-up-card.jpg");
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-position: center;
  border-radius: 15px; 
  height: 100%;
  width: 100%;
  /* margin-top: -10px; */
}

.ritgth-up-box-respon {
  background-image: url("~@/assets/img/temas-principales01-blog-mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 500px; 
  height: 200px; 
  margin-left: 70px;
  border-radius: 15px; 
  margin-top: 20px;
}

.right-down-card{
  height: 100%;
  width: 100%;
  margin-top: 35px;
}
.cardFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}
.noLinks {
  cursor: default !important;
}
.cardsLink {
  text-decoration: none !important;
}
.cardsLink:hover {
  text-decoration: underline !important;
  font-weight: bold !important;
}
</style>
