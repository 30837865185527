<template>
  <section :class="$vuetify.breakpoint.smAndDown ? 'cart-responsive' : 'cart'" style="background-color: #1e1e1e;">
    <v-row>
      <v-col cols="12" style="background-color: #1e1e1e;">

            <!-- <v-img src="@/assets/img/banner-tienda-desk.jpg" class="banner-img"/> -->

      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  data() {
    return {
      image: '@/assets/img/banner-tienda-desk.jpg'
    };
  },
  methods: {
    displayImage() {
      return console.log( this.$vuetify.breakpoint);
    }
  }
};
</script>

<style>
.cart {
  background-color: #1e1e1e;
  background-image: url("~@/assets/img/banner-tienda-desk.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 30px;
  /* background-position: center; */
  height: 700px;
  clip-path: inset(0);
}
.cart-responsive {
  background-color: #1e1e1e;
  background-image: url("~@/assets/img/banner-tienda-desk.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 30px;
  /* background-position: center; */
  height: 700px;
  clip-path: inset(0);
}
.banner-img {
  height: 700px;
  margin-left: -35px;
}
</style>
