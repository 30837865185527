<template>
  <section id="pricing" class="pb-9" style="background-color: white; margin-top: -10px; padding-top: 40px;">
    <v-container fluid >
      <v-row align="center" justify="center">
        <v-col cols="9" style="background-color: white;">
          <div style="width: 100%; background-color: white; border: 0px solid transparent;">
            <div style="width: 100%; display: flex; flex-direction: row; justify-content: center;">
              <div style="width: 50%;">
                <h1 class="text-center font-weight-bold display-1" style="color: black; padding: 20px;">
                  Más vendidos
                </h1>
              </div>
            </div>
            <v-row class="text-center mt-6" style="margin-top: -30px;">
              <v-col class="col-12 col-sm-12 col-md-12 col-lg-3" style="padding: 10px 20px 10px 20px; height: 350px; margin-bottom: 60px;" v-for="(producto, i) in productos" :key="i">
                <div class="flex-center" style="border-radius: 20px; height: 100%; width: 100%; background-color: white;">
                  <v-card-text style="color: white;">
                    <div class="flex-center">
                      <div class="circle1">
                        <div class="circle2" style="width: 100%">
                          <v-img
                            :src="producto.imagen"
                            :height="$vuetify.breakpoint.smAndDown ? '200px' : '200px'"
                            :width="$vuetify.breakpoint.smAndDown ? '300px' : '800px'"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="black--text mt-6">
                        <h1>
                          <b>{{ producto.name }}</b>
                        </h1>
                    </div>
                    <div class="black--text mt-5" style="text-align: center;" :class="i > 1 ? 'index-padd' : ''">
                      <p>{{producto.desc}}</p>
                    </div>
                    <v-btn
                      v-bind="size"
                      rounded
                      :style="{ backgroundImage: createBackgroundString }"
                      target="_blank"
                      href="https://wa.link/6fed0c"
                    >
                     Comprar
                  </v-btn>
                  </v-card-text>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  data: () => ({
    angle: '50',
    color1: '#d8ab3d',
    color2: '#e8bb45',
    productos: [
      {
        id: 1,
        name: "Franela Negra Unisex",
        imagen: require("../assets/products/black-shirt.jpg"),
        desc: "Todas las tallas",
        precio: 20
      },
      {
        id: 2,
        name: "Gorra Unisex",
        imagen: require("../assets/products/gorra01.jpg"),
        desc: "Gorra con logo, Colores variados",
        precio: 20
      },
      {
        id: 3,
        name: "Thermo de agua",
        imagen: require("../assets/products/thermo.jpg"),
        desc: "Thermo personalizado con logo de Reinas de Crypto",
        precio: 20
      },
      {
        id: 4,
        name: "Agenda Crypto",
        imagen: require("../assets/products/libreta01.jpg"),
        desc: "Agenda personalizada con logo de 80 paginas",
        precio: 20
      }
    ]
  }),
  computed: {
    size() {
      const size = { md: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    createBackgroundString() {
      return `linear-gradient(${this.angle}deg, ${this.color1}, ${this.color2})`;
    }
  }
};
</script>

<style lang="scss" scoped>
$main_color: #283e79;
ul {
  font-size: 13px;
  line-height: 1.5em;
  margin: 5px 0 15px;
  padding: 0;
  li {
    list-style: none;
    position: relative;
    padding: 0 0 0 20px;
  }
  li {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 5px;
      width: 10px;
      height: 10px;
      background-color: $main_color;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-eeborder-radius: 50%;
    }
  }
}
</style>

<style scoped>
.header {
  background-color: #283e79;
  color: white;
}
.circle1 {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle2 {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.index-padd {
  padding: 0px 40px 0px 40px;
}
.svg-border-rounded svg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #f4f7f5;
  z-index: -1;
}
#pricing {
  z-index: 0;
}
.content {
  z-index: 1;
}
svg {
  overflow: hidden;
}
section {
  position: relative;
}
</style>
