<template>
  <v-main class="back">
    <v-row
      :class="
        $vuetify.breakpoint.smAndDown
          ? 'image-location-responsive'
          : 'image-location'
      "
      align="center"
      justify="center"
    >
      <v-col cols="6" sm="12" lg="12" xl="12">
        <v-img
          src="../assets/img/NewLogo.png"
          :width="$vuetify.breakpoint.smAndDown ? 130 : 160"
          class="mx-auto"
        />
      </v-col>
    </v-row>
    <v-container fill-height fluid>
      <v-row align="center" justify="center" class="upper">
        <v-col cols="12" sm="12" lg="12" xl="12">
          <div class="card-container mx-auto">
            <v-card class="card-login">
              <v-row align="center" justify="center">
                <v-col cols="6" sm="12" lg="12" xl="12" class="text-center">
                  <h3>
                    Si desea enviarle a su Reina un regalo criptográfico, elija
                    una de las siguientes direcciones de tokens y envíe un
                    mensaje con la confirmación de su transacción de Ethereum
                    con el nombre de la Reina a la que le gustaría que se le
                    entregara el regalo.
                  </h3>
                </v-col>
                <v-col cols="12">
                  <v-col cols="12" class="text-center">
                    <h3>
                      Selecciona el codigo Wallet de tu preferencia
                    </h3>
                  </v-col>
                  <v-select
                    @change="inputValue"
                    :items="items"
                    solo
                    label="Seleccione un Wallet"
                  ></v-select>
                </v-col>
                <template v-if="inputShow">
                  <v-row align="center" justify="center" class="input-label">
                    <v-col cols="12">
                      <v-text-field
                        readonly
                        full-width
                        color="blue darken-2"
                        v-model="message"
                        :class="
                          $vuetify.breakpoint.smAndDown
                            ? 'select-width-responsive'
                            : 'select-width'
                        "
                      />
                    </v-col>
                    <!-- <v-col cols="5"> -->
                    <v-btn
                      :class="
                        $vuetify.breakpoint.smAndDown
                          ? 'btn-clipboard-responsive'
                          : 'btn-clipboard'
                      "
                      fab
                      small
                      type="button"
                      icon
                      v-clipboard:copy="message"
                      v-clipboard:success="onCopy"
                    >
                      <v-icon color="black">fa-solid fa-clipboard</v-icon>
                    </v-btn>
                    <!-- </v-col> -->
                  </v-row>
                </template>
              </v-row>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "Donations",
  data: () => ({
    items: [
      "Seleccione un Wallet",
      "Bitcoin",
      "Ethereum",
      "USDT",
      "Menero",
      "BNB",
      "XRP"
    ],
    inputShow: false,
    message: ""
  }),
  methods: {
    inputValue(el) {
      if (el === "Seleccione un Wallet") {
        this.message = "";
        this.inputShow = false;
      } else if (el === "Bitcoin") {
        this.message = "36tXz9FDsRsHrs6oc75sEgZMQWgHqnp63T";
        this.inputShow = true;
      } else if (el === "Ethereum") {
        this.message = "0x1bfb4f50d1ce0b66eafd03af4341411da6a6af4f";
        this.inputShow = true;
      } else if (el === "USDT") {
        this.message = "0x8cda5c92b728ebb89fb9c7155c5e5bdf91744704";
        this.inputShow = true;
      } else if (el === "Menero") {
        this.message =
          "85tFEdwYtPE4ceDc2oeGfh7bdRrjH4shwBSmET1mpDcaFJpRiHNMLodaXr9oVQjtr42pnHYtZXJ8D5zkS8jwgmyZSpkcVdF";
        this.inputShow = true;
      } else if (el === "BNB") {
        this.message =
          "bnb136ns6lfw4zs5hg4n85vdthaad7hq5m4gtkgf23 Memo 374611136";
        this.inputShow = true;
      } else if (el === "XRP") {
        this.message = "rNFugeoj3ZN8Wv6xhuLegUBBPXKCyWLRkB XRP Tag 1907399476";
        this.inputShow = true;
      }
    }
  },
  onCopy(e) {
    return e.text;
    // alert("You just copied the following text to the clipboard: " + e.text);
  }
};
</script>

<style scoped>
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.card-container {
  width: 50% !important;
}
.upper {
  margin-top: 285px !important;
}
.back {
  background-image: url("../assets/img/banner3.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
}
.image-location {
  position: absolute !important;
  top: 190px !important;
  left: 800px !important;
}
.image-location-responsive {
  position: absolute !important;
  top: 205px !important;
  right: -20px !important;
}
@keyframes opacity-in {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
@keyframes opacity-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.4;
  }
}
.card-login {
  opacity: 0.4;
  animation-name: opacity-out;
  animation-duration: 2s;
  padding: 30px !important;
}
.card-login:hover {
  opacity: 1;
  animation-name: opacity-in;
  animation-duration: 2s;
}
.alert-in {
  animation-name: opacity-in;
  animation-duration: 2s;
}
.select-width {
  width: 500px !important;
}
.select-width-responsive {
  width: 250px !important;
  margin-left: 30px !important;
}
.btn-clipboard {
  position: absolute;
  bottom: 30px !important;
  right: 270px !important;
}

.btn-clipboard-responsive {
  position: absolute;
  bottom: 45px !important;
  right: 30px !important;
}
</style>
